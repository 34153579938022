
































































































































































import { Component, Watch } from "vue-property-decorator";
import SchedulerForm from "@/views/Scheduler/SchedulerForm.vue";
import { namespace } from "vuex-class";
import Calendar from "./Calendar.vue";
import moment from "moment";
import CreateButton from "@/components/Button/CreateButton.vue";
import EditButton from "@/components/Button/EditButton.vue";
import Actions from "@/components/Actions.vue";
import { DRIVING_LESSON, GQC_OR_GQD, PRACTICAL_EXAM, THEORY_LESSON, THEORY_EXAM, OTHER, DRIVING_SCHOOL_APPOINTMENT } from "@/constants/Appointments";
import FullScreenButton from "@/components/Button/FullScreenButton.vue";
import PauseButton from "@/components/Button/PauseButton.vue";
import { mixins } from "vue-class-component";
import ViewSwitchMixin from "@/mixins/ViewSwitchMixin";
import { IInstructor } from "@/interfaces/IInstructor";
import { EDIT, COPY } from "@/constants/FormType";
import FscPageHeader from "@/components/FscPageHeader.vue";
import UserService from "@/services/UserService";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import CheckWorkingHoursModal from "@/components/Modal/CheckWorkingHoursModal.vue";
import CheckOverlappingAppointmentsModal from "@/components/Modal/CheckOverlappingAppointmentsModal.vue";
import CheckWorkingHoursAndOverlappingAppointmentMixin from "@/mixins/CheckWorkingHoursAndOverlappingAppointmentMixin.ts";
import { IAppointment } from "@/interfaces/IAppointment";
import { durationToTime } from "@/utils/DateTime";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { ALL, THIS_AND_FOLLOWING } from "@/constants/RecurringAppointments";
import CheckOverlappingAppointmentsVehicleModal from "@/components/Modal/CheckOverlappingAppointmentsVehicleModal.vue";
import TheoryLessonDetails from "@/views/Scheduler/TheoryLessonDetails.vue";
import _, { isBoolean } from "lodash";
import { ICalendarTime } from "@/interfaces/ICalendarTime";
import TheoryExamDetails from "@/views/Scheduler/TheoryExamDetails.vue";
import { boolenToEnum, OverlappingCheckEnum } from "@/enums/OverlappingCheckEnum";
import { ICheckOverlappingAppointmentsOptions } from "@/interfaces/ICheckOverlappingAppointmentsOptions";
import { ICheckWorkingHoursRepeatingEvent } from "@/interfaces/ICheckOverlappingAppointmentsRepeatingEventOptions";
import { IRecurring } from "@/interfaces/IRecurring";
import { IOtherAppointment } from "@/interfaces/IOtherAppointment";
import { IDrivingLessonPayload } from "@/interfaces/Payloads/IDrivingLessonPayload";
import { IPracticalExamStudentExamPayload } from "@/interfaces/Payloads/IPracticalExamStudentExamPayload";
import { IBasicNamedDTO } from "@/interfaces/IBasicNamedDTO";
import { IVehicle } from "@/interfaces/IVehicle";
import { formatInstructorName } from "@/utils/NameUtil";
import DrivingSchoolAppointmentMixin from "@/mixins/Request/DrivingSchoolAppointmentMixin";
import DrivingAppointmentDetails from "@/views/Scheduler/DrivingAppointmentDetails.vue";
import { getDrivingSchoolIsUsingMirrorVehicles } from "@/store/DrivingSchool/getters";
import { findDrivingSchoolIsUsingMirrorVehicles } from "@/store/DrivingSchool/actions";

const DrivingLessonModule = namespace("driving-lesson");
const CalendarModule = namespace("calendar");
const CalendarFilterModule = namespace("calendar/localFilter");
const InstructorModule = namespace("instructor");
const VehicleModule = namespace("vehicle");
const TheoryLessonModule = namespace("theory-lesson");
const OtherAppointment = namespace("other-appointment");
const UserModule = namespace("user");
const CalendarTheoryLessonModule = namespace("calendar-theory-lesson");
const CalendarPracticalExamModule = namespace("calendar-practical-exam");
const TheoryExamModule = namespace("theory-exam");
const DrivingSchoolModule = namespace("driving-school");

@Component({
  components: {
    DrivingAppointmentDetails,
    FscSimpleCard,
    FscPageHeader,
    PauseButton,
    FullScreenButton,
    Actions,
    EditButton,
    CreateButton,
    Calendar,
    SchedulerForm,
    CheckWorkingHoursModal,
    CheckOverlappingAppointmentsModal,
    CheckOverlappingAppointmentsVehicleModal,
    TheoryLessonDetails,
    TheoryExamDetails,
  },
})
export default class Scheduler extends mixins(ViewSwitchMixin, CheckWorkingHoursAndOverlappingAppointmentMixin, DrivingSchoolAppointmentMixin) {
  public name = "Scheduler";

  @CalendarFilterModule.State("filter")
  public calendarLocalFilter: any;

  @CalendarFilterModule.Mutation("SET_LOCAL_FILTER")
  public setCalendarLocalFilter!: (payload: Record<any, any>) => void;

  @DrivingLessonModule.Action("create")
  public drivingLessonCreate: any;

  // Calendar
  @CalendarModule.Action("filter")
  public filterCalendar: any;

  @CalendarModule.Getter("getDataList")
  public calendar: any;

  @CalendarModule.Getter("getIsLoading")
  public calendarIsLoading: any;

  @InstructorModule.Action("filter")
  public instructorFilter: any;

  @InstructorModule.Getter("getDataList")
  public instructors: any;

  @VehicleModule.Action("findAll")
  public vehicleFindAll: any;

  @VehicleModule.Action("vehiclesByCurrentInstructor")
  public instructorVehiclesFind: any;

  @VehicleModule.Getter("getDataList")
  public vehicles: any;

  @VehicleModule.Getter("getVehiclesByCurrentInstructor")
  public instructorVehicles: any;

  @DrivingLessonModule.Getter("getSuccess")
  public drivingLessonSuccess: any;

  @DrivingLessonModule.Getter("getIsLoading")
  public drivingLessonIsLoading: any;

  @DrivingLessonModule.Getter("getDataItem")
  public drivingLessonItem: any;

  @DrivingLessonModule.Action("findOne")
  public drivingLessonFindOne: any;

  @DrivingLessonModule.Action("booked")
  public drivingLessonBook: any;

  @DrivingLessonModule.Action("update")
  public drivingLessonUpdate: any;

  /* Practical exam store functions start */
  @CalendarPracticalExamModule.Getter("getSuccess")
  public practicalExamSuccess: any;

  @CalendarPracticalExamModule.Getter("getIsLoading")
  public practicalExamIsLoading: any;

  @CalendarPracticalExamModule.Getter("getDataItem")
  public practicalExamItem: any;

  @CalendarPracticalExamModule.Action("findOne")
  public practicalExamFindOne: any;

  @CalendarPracticalExamModule.Action("booked")
  public practicalExamBook: any;

  @CalendarPracticalExamModule.Action("update")
  public practicalExamUpdate: any;

  @CalendarPracticalExamModule.Mutation("SET_SUCCESS")
  public practicalExamSuccessMutation: any;
  /* Practical Exam store functions end */

  @TheoryLessonModule.Action("findOne")
  public theoryLessonFindOne: any;

  @OtherAppointment.Action("create")
  public otherAppointmentCreate: any;

  @OtherAppointment.Action("update")
  public otherAppointmentUpdate: any;

  @OtherAppointment.Action("findOne")
  public otherAppointmentFindOne: any;

  @OtherAppointment.Getter("getSuccess")
  public otherAppointmentSuccess: any;

  @OtherAppointment.Getter("getDataItem")
  public otherAppointmentItem: any;

  @OtherAppointment.Getter("getIsLoading")
  public otherAppointmentIsLoading: any;

  @OtherAppointment.Mutation("SET_SUCCESS")
  public otherAppointmentSuccessMutation: any;

  @DrivingLessonModule.Mutation("SET_SUCCESS")
  public drivingLessonSuccessMutation: any;

  @DrivingLessonModule.Action("deleteDrivingLesson")
  public deleteDrivingLesson: any;

  @OtherAppointment.Action("deleteOtherAppointment")
  public deleteOtherAppointment!: (options: ICrudOptions) => Promise<void>;

  @UserModule.Action("calendar-writeable/findAll")
  public calendarWriteableAction!: (options: ICrudOptions) => Promise<void>;

  @UserModule.Getter("calendar-writeable/getDataList")
  public calendarWriteable!: Array<IInstructor>;

  @UserModule.Action("calendar-readable/findAll")
  public calendarReadableAction!: (options: ICrudOptions) => Promise<void>;

  @UserModule.Getter("calendar-readable/getDataList")
  public calendarReadable!: Array<IInstructor>;

  @CalendarTheoryLessonModule.Getter("getIsLoading")
  public theoryLessonIsLoading: any;

  @CalendarTheoryLessonModule.Getter("getDataItem")
  public getTheoryLesson: any;

  @CalendarTheoryLessonModule.Action("findOne")
  public getTheoryLessonAction: any;

  @CalendarModule.Action("nightTimes/findAll")
  public findAllCalendarNightTimes!: (options: ICrudOptions) => Promise<void>;

  @CalendarModule.Getter("nightTimes/getDataList")
  public calendarNightTimes!: Array<ICalendarTime>;

  @TheoryExamModule.Action("findOne")
  public findTheoryExam: any;

  @TheoryExamModule.Getter("getDataItem")
  public singleTheoryExam: any;

  @TheoryExamModule.Getter("getIsLoading")
  public theoryExamIsLoading: any;

  @DrivingSchoolModule.Action("findDrivingSchoolIsUsingMirrorVehicles")
  public findDrivingSchoolIsUsingMirrorVehiclesFn: any;

  @DrivingSchoolModule.Getter("getDrivingSchoolIsUsingMirrorVehicles")
  public drivingSchoolIsUsingMirrorVehicles: any;

  public vehicle: any = [];
  public instructor: null | IInstructor = null;
  public selectedInstructors: IBasicNamedDTO[] = [];
  public initialInstructor: null | IBasicNamedDTO = null;
  public search = "";
  public drivingLessonsResource = "driving-lessons";
  public otherAppointmentResource = "other-appointment";
  public practicalExamResource = "practical-exams/student-event";
  public practicalExamUpdateResource = "practical-exams/update-student-exam";
  public appointmentTypeId = 0;
  public isFullscreen = false;
  public dateFromClick = "";
  protected businessHours = [];
  protected onSaveData: any = null;
  protected overlappingAppointmentsModalId = "check-overlapping-appointments-modal-calendar";
  protected workingHoursModalId = "check-working-hours-modal-calendar";
  public allowDropEvent = true;
  public minutesNew: any = null;
  public vehiclesNew: any = null;
  public timeNew: any = null;
  public noteNew: any = null;

  public get allowDrop() {
    return this.allowDropEvent;
  }

  public async onDelete(data: { appointmentTypeId: any; appointmentId: any }): Promise<void> {
    if (data.appointmentTypeId === DRIVING_LESSON) {
      await this.deleteDrivingLesson(data.appointmentId);
      if (this.drivingLessonSuccess) {
        await this.closeFormOverride();
        this.refetchCalendarEvents();
      }
    } else if (data.appointmentTypeId === GQC_OR_GQD) {
      await this.deleteDrivingLesson(data.appointmentId);
      if (this.drivingLessonSuccess) {
        await this.closeFormOverride();
        this.refetchCalendarEvents();
      }
    } else {
      await this.deleteOtherAppointment({
        resource: this.otherAppointmentResource,
        id: data.appointmentId,
      });
      if (this.otherAppointmentSuccess) {
        await this.closeFormOverride();
        this.refetchCalendarEvents();
      }
    }
  }

  public async onChangeMode() {
    await this.onCopyOverride();
  }

  public async onDrop(ctx: any) {
    const { revert } = ctx;
    if (!this.allowDropEvent) return false;

    const { start } = ctx.event;
    this.formType = EDIT;
    await this.getDataResourceFromCell(ctx.event);

    if (this.resourceItem) {
      let copyObject = Object.assign({}, _.cloneDeep(this.resourceItem));
      copyObject = {
        ...copyObject,
        instructor: this.resourceItem?.instructors ? this.resourceItem?.instructors : this.resourceItem?.instructor,
      };
      copyObject.date = moment(start).format("YYYY-MM-DD");
      copyObject.time = moment(start).format("HH:mm");
      const constructedData: any = {
        data: copyObject,
        appointmentType: { id: this.appointmentTypeId },
        recurringType: null,
        recurringSelectedOpt: null,
      };
      this.onSave(constructedData).then(() => {
        this.allowDropEvent = this.allowDropEvent && !this.dropActionIsConfirmed;

        if (!this.allowDropEvent) {
          revert();
          this.reRenderCalendar();
          return;
        }
      });
      this.reRenderCalendar();
    }
  }

  public onDragStart(ctx: any) {
    const { extendedProps } = ctx.event;
    if (
      (Object.keys(extendedProps).includes("booked") && extendedProps.booked) ||
      extendedProps.eventTypeId === THEORY_LESSON ||
      extendedProps.eventTypeId === PRACTICAL_EXAM
    ) {
      this.allowDropEvent = false;
      return;
    }
    this.allowDropEvent = true;
    this.dropActionIsConfirmed = true;
    this.$root.$emit("allow-drop", true);
  }

  public async checkOneOrMoreInstuctorWorkingHoursConflicts({ data, instructorData, instructorRecurringData, recurringType }: any) {
    // check instructor working hours for array of instructors start
    let instrData: any = Object.assign({}, instructorData);
    let instrRecurringData: any = Object.assign({}, instructorRecurringData);
    if (Array.isArray(data.data.instructor)) {
      let hoursConflictsArray: any = [];
      data.data.instructor.map(async (instructor: any) => {
        instrData = {
          ...instructorData,
          instructorId: instructor?.id || null,
        };
        if (recurringType) {
          instrRecurringData = {
            ...instructorRecurringData,
            instructorId: instructor?.id || null,
          };
        }
        // check instructor working hours
        let checkWorkingHours;
        if (instructorRecurringData) {
          checkWorkingHours = await this.checkInstructorWorkingHoursRepeatEventFn(instrRecurringData);
        } else {
          checkWorkingHours = await this.checkInstructorWorkingHoursFn(instrData);
        }
        // if (boolenToEnum(checkWorkingHours) === OverlappingCheckEnum.NO) {
        //   this.allowDropEvent = false;
        //   return;
        // } else {
        //   this.checkHoursModalConfirmed = true;
        // }
        hoursConflictsArray.push(checkWorkingHours);
        if (data.data.instructor.length == hoursConflictsArray.length) {
          if (hoursConflictsArray.includes(1)) {
            this.checkHoursModalConfirmed = false;
          } else {
            this.checkHoursModalConfirmed = true;
          }
        }
      });
    } else {
      // check instructor working hours for single instructor
      instrData = {
        ...instructorData,
        instructorId: data.data.instructor?.id || null,
      };

      if (recurringType) {
        instrRecurringData = {
          ...instructorRecurringData,
          instructorId: data.data.instructor?.id || null,
        };
      }

      // check instructor working hours
      if (!this.checkHoursModalConfirmed) {
        let checkWorkingHours;
        if (instructorRecurringData) {
          checkWorkingHours = await this.checkInstructorWorkingHoursRepeatEventFn(instructorRecurringData);
        } else {
          checkWorkingHours = await this.checkInstructorWorkingHoursFn(instrData);
        }

        if (checkWorkingHours === 1) {
          this.allowDropEvent = false;
          return;
        } else {
          this.checkHoursModalConfirmed = true;
        }
      }
    }
    //END of check instructor working hours for one or more instructors
  }

  public async checkInstructorOverlappingAppointmentConflicts({ data, instructorRecurringData, instructorData }: any) {
    let checkOverlapping;
    if (Array.isArray(data.data?.instructor)) {
      let appointmentsConflictsArray: any = [];
      data.data.instructor.map(async (instructor: any) => {
        this.checkOverlappingAppointmentModalConfirmed = false;
        const instrData = {
          ...instructorData,
          instructorId: instructor.id,
        };
        const instrRecurringData = {
          ...instructorRecurringData,
          instructorId: instructor.id,
        };
        if (!this.checkOverlappingAppointmentModalConfirmed) {
          if (instructorRecurringData) {
            checkOverlapping = await this.checkInstructorOverlappingAppointmentsRepeatEventFn(instrRecurringData);
          } else {
            checkOverlapping = await this.checkInstructorOverlappingAppointmentsFn(instrData);
          }

          // if (boolenToEnum(checkOverlapping) === OverlappingCheckEnum.NO) {
          //   this.allowDropEvent = false;
          //   return;
          // } else {
          //   this.checkOverlappingAppointmentModalConfirmed = true;
          // }
          appointmentsConflictsArray.push(checkOverlapping);
          if (data.data.instructor.length == appointmentsConflictsArray.length) {
            if (appointmentsConflictsArray.includes(1)) {
              this.checkOverlappingAppointmentModalConfirmed = false;
            } else {
              this.checkOverlappingAppointmentModalConfirmed = true;
            }
          }
        }
      });
    } else {
      if (!this.checkOverlappingAppointmentModalConfirmed) {
        if (instructorRecurringData) {
          checkOverlapping = await this.checkInstructorOverlappingAppointmentsRepeatEventFn(instructorRecurringData);
        } else {
          checkOverlapping = await this.checkInstructorOverlappingAppointmentsFn(instructorData);
        }

        if (checkOverlapping === 1) {
          this.allowDropEvent = false;
          return;
        } else {
          this.checkOverlappingAppointmentModalConfirmed = true;
        }
      }
    }
  }

  public async onSave(data: {
    data: IAppointment | any;
    appointmentType: any;
    new: boolean;
    recurringType: null | IRecurring;
    recurringSelectedOpt: string | null;
  }): Promise<void> {
    this.onSaveData = data;
    const { recurringType } = data;
    const { date, time, duration, durationInMinutes } = data.data;

    const endTime = durationToTime(time, durationInMinutes ?? duration);
    const startDateTime = this.toDateTime(date, time);
    const endDateTime = this.toDateTime(date, endTime);

    const eventId = this.resourceItem?.id || null;

    let instructorRecurringData: ICheckWorkingHoursRepeatingEvent | null = null;
    let vehicleRecurringData;

    if (recurringType) {
      instructorRecurringData = {
        instructorId: data.data.instructor?.id || null,
        data: {
          startDate: date,
          endDate: recurringType.repeatEndDate,
          recurringType: recurringType.recurringType || null,
          timeFrame: recurringType.timeFrame || [],
          daysOfWeek: [],
        },
      };

      vehicleRecurringData = {
        vehicleId: data.data.vehicle?.id || null,
        data: {
          startDate: startDateTime,
          endDate: recurringType.repeatEndDate,
          recurringType: recurringType.recurringType || null,
          timeFrame: recurringType.timeFrame || [],
          daysOfWeek: [],
        },
      };
    }
    let instructorData: ICheckOverlappingAppointmentsOptions = {
      instructorId: data.data.instructor?.id || null,
      data: {
        startTime: startDateTime,
        endTime: endDateTime,
        eventId: data.new ? null : eventId,
        eventTypeId: data.appointmentType.id,
      },
    };

    const vehicleData = {
      vehicleId: data.data.vehicle?.id || null,
      data: {
        startTime: startDateTime,
        endTime: endDateTime,
        eventId: data.new ? null : eventId,
        eventTypeId: data.appointmentType.id,
      },
    };

    // CHECK INSTRUCTOR WORKING HOURS CONFLICTS WHEN THEY ARE ONE OR MORE - IN CASE OF OTHER APPOINTMENT
    if (instructorData && !this.checkHoursModalConfirmed) {
      await this.checkOneOrMoreInstuctorWorkingHoursConflicts({
        data,
        instructorData,
        instructorRecurringData,
        recurringType,
      });
    }

    // CHECK INSTRUCTOR APPOINTMENT OVERLAPPING
    if (instructorData && !this.checkOverlappingAppointmentModalConfirmed) {
      await this.checkInstructorOverlappingAppointmentConflicts({
        data,
        instructorRecurringData,
        instructorData,
      });
    }
    // check main vehicle
    if (!this.checkOverlappingAppointmentVehicleModalConfirmed && (data.data.vehicle?.id || data.data.vehicleIds)) {
      let checkVehicleOverlapping;
      if (this.isPracticalExam) {
        checkVehicleOverlapping = await this.checkVehicleOverlappingAppointmentArrayFn({
          vehicleId: data.data.vehicle.id,
          data: {
            startTime: startDateTime,
            endTime: endDateTime,
            eventId: data.new ? null : eventId,
            eventTypeId: data.appointmentType.id,
            vehicleIds: data.data.vehicleIds ? data.data.vehicleIds : [],
          },
        });
      } else if (vehicleRecurringData) {
        checkVehicleOverlapping = await this.checkVehicleOverlappingAppointmentsRepeatEventFn(vehicleRecurringData);
      } else {
        checkVehicleOverlapping = await this.checkVehicleOverlappingAppointmentsFn(vehicleData);
      }
      if (boolenToEnum(checkVehicleOverlapping) === OverlappingCheckEnum.NO) {
        this.allowDropEvent = false;
        return;
      } else {
        this.checkOverlappingAppointmentVehicleModalConfirmed = true;
      }
    }

    // Check trailer vehicle
    if (!this.checkOverlappingAppointmentVehicleTrailerModalConfirmed && data.data.trailer) {
      const checkVehicleTrailerOverlapping = await this.checkVehicleOverlappingAppointmentsFn(
        {
          ...vehicleData,
          vehicleId: data.data.trailer?.id || null,
        },
        this.overlappingAppointmentsVehicleTrailerModalId
      );

      if (boolenToEnum(checkVehicleTrailerOverlapping) === OverlappingCheckEnum.NO) {
        this.allowDropEvent = false;
        return;
      } else {
        this.checkOverlappingAppointmentVehicleTrailerModalConfirmed = true;
      }
    }

    // Check escort vehicle
    if (!this.checkOverlappingAppointmentVehicleEscortModalConfirmed && data.data.escortVehicle) {
      const checkVehicleEscortOverlapping = await this.checkVehicleOverlappingAppointmentsFn(
        {
          ...vehicleData,
          vehicleId: data.data?.escortVehicle?.id || null,
        },
        this.overlappingAppointmentsVehicleEscortModalId
      );

      if (boolenToEnum(checkVehicleEscortOverlapping) === OverlappingCheckEnum.NO) {
        this.allowDropEvent = false;
        return;
      } else {
        this.checkOverlappingAppointmentVehicleEscortModalConfirmed = true;
      }
    }

    if (data.appointmentType.id === DRIVING_LESSON) {
      const drivingLesson: IDrivingLessonPayload = {
        id: null,
        date: data.data.date,
        time: data.data.time,
        duration: data.data.duration,
        instructor: {
          id: data.data.instructor.id,
        },
        licenseClass: data.data.licenseClass,
        product: {
          id: data.data.product.id,
        },
        student: {
          id: data.data.student.id,
        },
        vehicle: data.data.vehicle?.id
          ? {
              id: data.data.vehicle.id,
            }
          : null,
        escortVehicle: data.data.escortVehicle?.id
          ? {
              id: data.data.escortVehicle.id,
            }
          : null,
        trailer: data.data.trailer?.id
          ? {
              id: data.data.trailer.id,
            }
          : null,
        kilometersDriven: data.data.kilometersDriven,
        escortVehicleKilometersDriven: data.data.escortVehicleKilometersDriven,
        note: data.data.note,
        booked: data.data.booked,
        durationInMinutes: data.data.duration,
      };
      if (this.typeEdit()) {
        const { id } = this.drivingLessonItem;
        // Update driving lesson
        await this.drivingLessonUpdate({
          data: { ...drivingLesson, id },
          id,
          resource: this.drivingLessonsResource,
        });
      } else {
        // Create driving lesson
        await this.drivingLessonCreate({
          data: drivingLesson,
          resource: this.drivingLessonsResource,
        });
      }
    } else if (data.appointmentType.id === GQC_OR_GQD) {
      const drivingLesson: IDrivingLessonPayload = {
        id: null,
        date: data.data.date,
        time: data.data.time,
        duration: data.data.duration,
        instructor: {
          id: data.data.instructor.id,
        },
        licenseClass: data.data.licenseClass,
        product: {
          id: data.data.product.id,
        },
        student: {
          id: data.data.student.id,
        },
        vehicle: data.data.vehicle?.id
          ? {
              id: data.data.vehicle.id,
            }
          : null,
        escortVehicle: data.data.escortVehicle?.id
          ? {
              id: data.data.escortVehicle.id,
            }
          : null,
        trailer: data.data.trailer?.id
          ? {
              id: data.data.trailer.id,
            }
          : null,
        kilometersDriven: data.data.kilometersDriven,
        escortVehicleKilometersDriven: data.data.escortVehicleKilometersDriven,
        note: data.data.note,
        booked: data.data.booked,
        durationInMinutes: data.data.duration,
      };
      if (this.typeEdit()) {
        const { id } = this.drivingLessonItem;
        // Update gqd/gqc
        await this.drivingLessonUpdate({
          data: { ...drivingLesson, id },
          id,
          resource: this.drivingLessonsResource,
        });
      } else {
        // Create gqd/gqc
        await this.drivingLessonCreate({
          data: drivingLesson,
          resource: this.drivingLessonsResource,
        });
      }
    } else if (data.appointmentType.id === PRACTICAL_EXAM) {
      const practicalExam: IPracticalExamStudentExamPayload = {
        id: null,
        studentId: data.data.studentId,
        licenseClass: data.data.licenseClass,
        instructorId: data.data.instructorId,
        vehicleIds: data.data.vehicleIds,
        time: data.data.time,
        kilometersDriven: data.data.kilometersDriven,
        note: data.data.note,
      };

      if (this.typeEdit()) {
        const { id } = this.practicalExamItem;
        // Update practical exam
        await this.practicalExamUpdate({
          data: { ...practicalExam, id },
          id: "",
          resource: this.practicalExamUpdateResource,
        });
      }
    } else {
      const instructors: any = data.data.instructor?.id ? [data.data.instructor.id] : data.data.instructor.map((inst: any) => inst.id);
      const otherAppointment: IOtherAppointment = {
        id: null,
        name: data.data.name,
        note: data.data.note,
        privateAppointment: data.data.privateAppointment,
        date: data.data.date,
        time: data.data.time,
        durationInMinutes: data.data.durationInMinutes,
        instructors: instructors,
        vehicle: data.data.vehicle?.id
          ? {
              id: data.data.vehicle.id,
            }
          : null,
        includeInPayroll: data.data.includeInPayroll,
        includeInInstructorTime: data.data.includeInInstructorTime,
        endDate: data.recurringType?.repeatEndDate,
        recurringType: data.recurringType?.recurringType,
        kilometersDriven: data.data?.kilometersDriven,
      };
      if (this.typeEdit()) {
        let res = this.otherAppointmentResource;
        // Build serial appointment update URL
        if (data.recurringSelectedOpt === ALL) {
          res = `${res}/serial-all`;
        } else if (data.recurringSelectedOpt === THIS_AND_FOLLOWING) {
          res = `${res}/serial-future`;
        }
        // Update other appointment
        const { id } = this.otherAppointmentItem;
        await this.otherAppointmentUpdate({
          data: {
            ...otherAppointment,
            id,
          },
          id,
          resource: res,
        });
      } else {
        if (!data.recurringType && this.checkHoursModalConfirmed && this.checkOverlappingAppointmentModalConfirmed) {
          // Create other appointment
          await this.otherAppointmentCreate({
            data: otherAppointment,
            resource: this.otherAppointmentResource,
          });
        } else if (!data.recurringType) {
          return;
        } else {
          await this.otherAppointmentCreate({
            data: {
              ...otherAppointment,
            },
            resource: `${this.otherAppointmentResource}/recurring`,
          });
        }
      }
    }

    if (this.drivingLessonSuccess || this.otherAppointmentSuccess || this.practicalExamSuccess) {
      this.refetchCalendarEvents();
      if (!data.new) {
        await this.closeFormOverride();
      }
      this.drivingLessonSuccessMutation(false);
      this.otherAppointmentSuccessMutation(false);
      this.practicalExamSuccessMutation(false);
    }
    this.onSaveData = null;
    this.resetConfirmationState();
    if (data.new) {
      this.dateFromClick = data.data.date;
      this.vehiclesNew = data.data.vehicle;
      this.minutesNew = data.data.duration;
      this.timeNew = data.data.time;
      this.noteNew = data.data.note;
    }
  }

  public async handleEvents(event: any): Promise<void> {
    const instructors = this.selectedInstructors.map((inst: any) => {
      return inst.id;
    });
    const fromDate = moment(event.ctx.start.valueOf()).format("YYYY-MM-DD");
    const filter = {
      fromDate,
      toDate: moment(event.ctx.end.valueOf()).format("YYYY-MM-DD"),
    };
    if (this.selectedInstructors) {
      Object.assign(filter, {
        instructorIds: instructors,
      });
    }

    if (this.vehicle) {
      const vehicleId: any = [];
      this.vehicle.map((vehicle: any) => {
        vehicleId.push(vehicle.id);
        return vehicleId;
      });
      Object.assign(filter, {
        vehicleIds: vehicleId,
      });
    }

    await this.fetchCalendarTimes(fromDate);

    await this.filterCalendar(filter);

    const currentView = await (this.$refs.calendar as Calendar).getCurrentView();

    await (this.$refs.calendar as Calendar).setOption("businessHours", this.calendar.businessHours);

    if (currentView.type == "resourceTimeGridDay") {
      const { events, resources } = this.filterResources;

      await (this.$refs.calendar as Calendar).setOption("resources", resources);

      event.successCallback(events);
    } else {
      event.successCallback(this.calendar.events);
    }
  }

  public get filterResources() {
    const { selectedInstructors, selectedVehicles } = this.calendarResources;

    let instructorEvents = [];
    let vehicleEvents = [];

    if (selectedInstructors.length) {
      instructorEvents = this.calendar.events
        .filter((event: any) => {
          return selectedInstructors.some((i: any) => {
            return event.instructorIds && event.instructorIds.length > 0 && event.instructorIds.some((instrId: any) => instrId === i.originalId);
          });
        })
        .map((event: any, i: any) => {
          const someInstructor = selectedInstructors.find((i: any) => {
            return event.instructorIds && event.instructorIds.length > 0 && event.instructorIds.some((instrId: any) => instrId === i.originalId);
          });
          return {
            ...event,
            resourceId: someInstructor?.id,
            // resourceId: someInstructor?.id ? someInstructor?.id : `${event.instructorIds[0]}-instructor`,
          };
        });
    }

    if (selectedVehicles.length) {
      vehicleEvents = this.calendar.events
        .filter((event: any) =>
          selectedVehicles.some((i: any) => {
            return (event.vehicleId && event.vehicleId == i.originalId) || !event.vehicleAvailableDuringEvent;
          })
        )
        .map((event: any) => {
          return {
            ...event,
            resourceId: `${event.vehicleId}-vehicle`,
          };
        });
    }

    return {
      events: instructorEvents.concat(vehicleEvents),
      resources: selectedInstructors.concat(selectedVehicles),
    };
  }

  public get calendarResources() {
    const selectedInstructors = this.selectedInstructors.map((ins: IBasicNamedDTO) => {
      return {
        id: `${ins.id}-instructor`,
        title: ins.name,
        originalId: ins.id,
      };
    });

    const selectedVehicles = this.vehicle.map((vehicle: IVehicle) => {
      return {
        id: `${vehicle.id}-vehicle`,
        title: vehicle.brandAndModel,
        originalId: vehicle.id,
      };
    });

    return {
      selectedInstructors,
      selectedVehicles,
    };
  }

  public async onEditOverride(): Promise<void> {
    this.formType = EDIT;
    await this.openForm();
    this.reRenderCalendar();
  }

  public reRenderCalendar(): void {
    (this.$refs.calendar as Calendar).renderCalendar();
  }

  public refetchCalendarEvents(): void {
    (this.$refs.calendar as Calendar).refetchEvents();
  }

  public async onCreateOverride(): Promise<void> {
    this.appointmentTypeId = 0;
    await this.onCreate();
    this.reRenderCalendar();
  }

  public async onCopyOverride(): Promise<void> {
    this.formType = COPY;
    await this.openForm();
    this.reRenderCalendar();
  }

  public async createOnCalendarClick(ctx: any): Promise<void> {
    // After dataFromClick init it is sent as Prop to ScheduleForm.
    this.appointmentTypeId = 0;
    this.dateFromClick = ctx.startStr;
    await this.onCreate();
    this.reRenderCalendar();
  }

  public get instructorOption(): Array<any> {
    return this.calendarReadable.reduce(
      (prevValue: Array<any>, instructor: IInstructor) => {
        prevValue.push({
          name: formatInstructorName(instructor.firstName, instructor.lastName),
          initials: instructor.initials,
          id: instructor.id,
        });
        return prevValue;
      },
      [this.reformatCurrentInstructor]
    );
  }

  public created() {
    if (this.calendarLocalFilter instanceof Object) {
      this.selectedInstructors = this.calendarLocalFilter.instructor;
      this.vehicle = this.calendarLocalFilter.vehicle;
    } else {
      this.selectedInstructors.push(this.reformatCurrentInstructor);
    }
  }

  public mounted(): void {
    this.instructorFilter({ resource: "instructor", filter: {} });
    this.vehicleFindAll({ resource: "vehicles" });
    this.instructorVehiclesFind();
    this.calendarWriteableAction({
      resource: "/users/with-writable-calendars-by-current-user",
    });
    this.calendarReadableAction({
      resource: "users/with-readable-calendars-by-current-user",
    });
    this.findDrivingSchoolIsUsingMirrorVehiclesFn();
  }

  public onFullScreen(): void {
    this.isFullscreen = !this.isFullscreen;

    this.$nextTick(() => {
      this.reRenderCalendar();
    });
  }

  public onPause(): void {
    //console.log("on pause");
  }

  public async closeFormOverride(): Promise<void> {
    await this.closeForm();
    this.reRenderCalendar();
    this.removeSelectedHighlight();
  }

  public async openFormForTheoryLesson(id: any): Promise<void> {
    await this.getTheoryLessonAction({
      resource: "theory-lesson",
      id,
    });
    this.openFormTheoryLesson();
  }

  public async openFormForTheoryExam(id: any): Promise<void> {
    await this.findTheoryExam({
      resource: "theory-exam",
      id,
    });
    this.openFormTheoryExam();
  }

  public async openFormForDrivingSchoolAppointment(id: any): Promise<void> {
    await this.fetchDrivingSchoolAppointment(id);
    this.openFormDrivingSchoolAppointment();
  }

  public async getDataResourceFromCell(data: any): Promise<void> {
    const { extendedProps, id, allDay } = data;
    this.appointmentTypeId = extendedProps.eventTypeId;
    if (allDay) return;

    if (this.isDrivingLesson) {
      await this.drivingLessonFindOne({
        id: id,
        resource: this.drivingLessonsResource,
      });
    } else if (this.isGQCorGQD) {
      await this.drivingLessonFindOne({
        id: id,
        resource: this.drivingLessonsResource,
      });
    } else {
      await this.otherAppointmentFindOne({
        id: id,
        resource: this.otherAppointmentResource,
      });
    }
  }

  public async handleEventClick(ctx: any): Promise<void> {
    const { extendedProps, id, allDay, title } = ctx.event;
    const currentUser = UserService.getUser();

    if (extendedProps.eventTypeId === DRIVING_SCHOOL_APPOINTMENT && extendedProps.instructorIds === null) {
      await this.openFormForDrivingSchoolAppointment(id);
      return;
    }
    if (!extendedProps.instructorIds.includes(currentUser.id) && extendedProps.privateAppointment) {
      await this.closeForm();
      this.reRenderCalendar();
      return;
    }
    if (extendedProps.eventTypeId === THEORY_LESSON) {
      await this.openFormForTheoryLesson(id);
      return;
    }

    if (extendedProps.eventTypeId === THEORY_EXAM) {
      await this.openFormForTheoryExam(id);
      return;
    }

    this.appointmentTypeId = extendedProps.eventTypeId;

    // If event is allDay skip request to server
    if (allDay) return;

    if (this.isDrivingLesson) {
      await this.drivingLessonFindOne({
        id: id,
        resource: this.drivingLessonsResource,
      });
    } else if (this.isGQCorGQD) {
      await this.drivingLessonFindOne({
        id: id,
        resource: this.drivingLessonsResource,
      });
    } else if (this.isPracticalExam) {
      await this.practicalExamFindOne({
        id: id,
        resource: this.practicalExamResource,
      });
    } else {
      await this.otherAppointmentFindOne({
        id: id,
        resource: this.otherAppointmentResource,
      });
    }
    await this.onEditOverride();
  }

  public get dataItem(): any {
    return this.typeCreate() ? {} : this.resourceItem;
  }

  public get resourceItem(): any {
    if (this.isDrivingLesson) {
      return {
        ...this.drivingLessonItem,
        defaultDuration: this.drivingLessonItem?.duration,
        defaultDate: this.drivingLessonItem?.date,
        defaultTime: this.drivingLessonItem?.time,
      };
    }
    if (this.isGQCorGQD) {
      return {
        ...this.drivingLessonItem,
        defaultDuration: this.drivingLessonItem?.duration,
        defaultDate: this.drivingLessonItem?.date,
        defaultTime: this.drivingLessonItem?.time,
      };
    }
    if (this.isPracticalExam) {
      return {
        ...this.practicalExamItem,
        defaultDuration: this.practicalExamItem?.duration,
        defaultDate: this.practicalExamItem?.date,
        defaultTime: this.practicalExamItem?.time,
      };
    }
    return {
      ...this.otherAppointmentItem,
      defaultDuration: this.otherAppointmentItem?.durationInMinutes,
      defaultDate: this.otherAppointmentItem?.date,
      defaultTime: this.otherAppointmentItem?.time,
    };
  }

  public async onBooked(options: any): Promise<void> {
    if (this.isDrivingLesson) {
      const { id } = this.drivingLessonItem;
      await this.drivingLessonBook({ id, options });

      if (this.drivingLessonSuccess) {
        await this.closeForm();
        this.refetchCalendarEvents();
      }
    }
    if (this.isGQCorGQD) {
      const { id } = this.drivingLessonItem;
      await this.drivingLessonBook({ id, options });

      if (this.drivingLessonSuccess) {
        await this.closeForm();
        this.refetchCalendarEvents();
      }
    }
  }

  public get isDrivingLesson(): boolean {
    return this.appointmentTypeId === DRIVING_LESSON;
  }

  public get isGQCorGQD(): boolean {
    return this.appointmentTypeId === GQC_OR_GQD;
  }

  public get isPracticalExam(): boolean {
    return this.appointmentTypeId === PRACTICAL_EXAM;
  }

  public get isDrivingSchoolAppointment(): boolean {
    return this.appointmentTypeId === DRIVING_SCHOOL_APPOINTMENT;
  }

  public get reformatCurrentInstructor(): any {
    const user = UserService.getUser();
    return {
      name: formatInstructorName(user.firstName, user.lastName),
      id: user.id,
      initials: user.initials ? user.initials : `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`,
    };
  }

  public onSelectVehicle(): void {
    this.setCalendarLocalFilter({ instructor: this.selectedInstructors, vehicle: this.vehicle });
    this.refetchCalendarEvents();
  }

  public onSelectInstructor(): void {
    this.setCalendarLocalFilter({ instructor: this.selectedInstructors, vehicle: this.vehicle });
    this.refetchCalendarEvents();
  }

  @Watch("selectedInstructors", { immediate: true })
  public onChangeInstructorImmediate(): void {
    this.initialInstructor = this.selectedInstructors[0];

    this.$nextTick(() => {
      const calendar = this.$refs.calendar as Calendar;

      const currentAllDaySlotVisibility = calendar.getOption("allDaySlot");

      if (this.selectedInstructors.length > 1 && currentAllDaySlotVisibility === true) {
        calendar.setOption("allDaySlot", false);
      } else if (this.selectedInstructors.length == 1 && currentAllDaySlotVisibility === false) {
        calendar.setOption("allDaySlot", true);
      }
    });
  }

  protected get user(): any {
    return UserService.getUser();
  }

  protected removeSelectedHighlight(): void {
    (this.$refs.calendar as Calendar).removeSelectedHighlight();
  }

  protected submitAgain(): void {
    this.onSave(this.onSaveData);
  }

  protected okWorkingHours(): void {
    this.checkHoursModalConfirmed = true;
    this.submitAgain();
  }

  protected okOverlappingAppointments(): void {
    this.checkOverlappingAppointmentModalConfirmed = true;
    this.submitAgain();
  }

  protected okOverlappingAppointmentsVehicle(): void {
    this.checkOverlappingAppointmentVehicleModalConfirmed = true;
    this.submitAgain();
  }

  protected okOverlappingAppointmentsVehicleEscort(): void {
    this.checkOverlappingAppointmentVehicleEscortModalConfirmed = true;
    this.submitAgain();
  }

  protected okOverlappingAppointmentsVehicleTrailer(): void {
    this.checkOverlappingAppointmentVehicleTrailerModalConfirmed = true;
    this.submitAgain();
  }

  protected async onDeleteAll(data: { appointmentTypeId: any; appointmentId: any }): Promise<void> {
    await this.deleteOtherAppointment({
      resource: `${this.otherAppointmentResource}/serial-all`,
      id: data.appointmentId,
    });
    if (this.otherAppointmentSuccess) {
      await this.closeFormOverride();
      this.refetchCalendarEvents();
    }
  }

  protected async onDeleteFuture(data: { appointmentTypeId: any; appointmentId: any }): Promise<void> {
    await this.deleteOtherAppointment({
      resource: `${this.otherAppointmentResource}/serial-future`,
      id: data.appointmentId,
    });
    if (this.otherAppointmentSuccess) {
      await this.closeFormOverride();
      this.refetchCalendarEvents();
    }
  }

  private async fetchCalendarTimes(date: string): Promise<void> {
    await this.findAllCalendarNightTimes({
      resource: `/calendar/day-night-times/${date}`,
    });
  }

  private limitText(count: number) {
    return `und ${count} mehr`;
  }

  public get loading() {
    return this.drivingLessonIsLoading || this.practicalExamIsLoading || this.otherAppointmentIsLoading;
  }
}
